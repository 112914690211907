import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import { PosthogService } from 'src/app/services/posthog.service';

@Component({
  selector: 'app-invest-document-viewfinder',
  templateUrl: './invest-document-viewfinder.component.html',
  styleUrls: ['./invest-document-viewfinder.component.scss'],
})
export class InvestDocumentViewfinderComponent implements OnInit {

  @Output() public prevEvent = new EventEmitter<any>();
  @Output() public goToSignDocuments = new EventEmitter<any>();
  @Input() documents: any;
  @Input() btnList = true;

  platform: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private amplitudeService: AmplitudeService,
    private http: HttpClient
    //private posthog: PosthogService
    ) { }

  url: string;
  zoom = 1;
  currentselected = 0;
  readedByTime = false;
  readedByScroll = false;
  numberOfPages: number;
  currentPage: number;

  ngOnInit() {
    this.currentPage = 1;
    for (let i = 0; i < this.documents.length; i++) {
      if (i === 0) {
        this.url = this.documents[i].url;
        this.documents[i]['selected'] = true;
      } else {
        this.documents[i]['selected'] = false;
      }
      this.documents[i]['id'] = i;
    }
    //this.posthog.pushEvent('reading_invest_document');
    setTimeout(() => this.readedByTime = true, 30000);
    this.amplitudeService.trackEvent('invest_documents_showed', {});
    this.platform = Capacitor.getPlatform();
    this.cdRef.checkNoChanges();
  }

  selectDocument(id) {
    for (let i = 0; i < this.documents.length; i++) {
      if (i === id) {
        this.url = this.documents[i].url;
        this.documents[i]['selected'] = true;
      } else {
        this.documents[i]['selected'] = false;
      }
    }
    this.cdRef.checkNoChanges();
  }

  next() {
    this.goToSignDocuments.emit();
    this.sendPosthogEvent();
    this.cdRef.checkNoChanges();
  }

  prev() {
    this.prevEvent.emit();
    this.sendPosthogEvent();
    this.cdRef.checkNoChanges();
  }

  pageChange(value) {
    this.currentPage = value.pageNumber;
    if (value.pageNumber > 6) {
      this.readedByScroll = true;
    }
    this.cdRef.checkNoChanges();
  }

  sendPosthogEvent() {
    if (this.readedByTime && this.readedByScroll) {
      //this.posthog.pushEvent('finish_readed_invest_document');
      this.amplitudeService.trackEvent('invest_documents_readed', { readed: true });
    } else {
      this.amplitudeService.trackEvent('invest_documents_readed', { readed: false });
      //this.posthog.pushEvent('finish_no_readed_invest_document');
    }
    this.cdRef.checkNoChanges();
  }

  getNumberOfPages(pages) {
    this.numberOfPages = pages;
    this.cdRef.checkNoChanges();
  }

  downloadDocumentation() {
    for (let i = 0; i < this.documents.length; i++) {
      this.downloadFile(this.documents[i]['url']).subscribe(response => {
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.documents[i]['name']}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.cdRef.checkNoChanges();
      });
      this.cdRef.checkNoChanges();
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res: Blob) => {
        return res;
      })
    );
  }

}

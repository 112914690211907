<div class="general-container flex flex-col md:flex-row justify-center gap-32" [ngClass]="{'success': successOperation}">
  <div class="fixed md:hidden hover:cursor-pointer top-[46px] right-[22px]">
      <fun-icon [name]="'info-circle'" [style]="'w-32 h-32 stroke-gray-40 stroke-2'" (click)="openInfo()"></fun-icon>
  </div>  
  <div class="header flex flex-col divide-y divide-gray-10 gap-12 w-full md:w-1/3 p-20 rounded-xl bg-gray-10/[0.1]">
    <div class="titleContainer pb-16">
        <h2 class="font-sans text-eggplant-100 text-xl pb-16">{{ 'MOVEMENTS.MONEY-OUT.HOW-CHANGE' | translate }}</h2>
        <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.MONEY-OUT.HOW-CHANGE-INFO' | translate }}</p>
    </div>
  </div>
  <div class="content p-0 md:p-20">
    <div class="operation" *ngIf="!successOperation">
        <form [formGroup]="bankForm" (submit)="save()">
          <div class="flex flex-col gap-32">
            <div class="flex flex-col gap-12">
              <fun-input
              (keyup)="controlForm()"
              placeholder="ej: Miguel Angel Pérez"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="bank"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.NAME' | translate }}"
              ></fun-input>
              <fun-input
              (keyup)="controlForm()"
              placeholder="**** **** **** **** ****"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="iban"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.ACCOUNT_NUMBER' | translate }}"
              isIban="true"
              ></fun-input>
              <fun-input
              (selectChange)="controlForm()"
              placeholder="{{ 'MOVEMENTS.MONEY-OUT.ACCOUNT_TYPE' | translate }}"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="accountType"
              type="select"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.ACCOUNT_TYPE' | translate }}"
              [items]="accountTypeList"
              [selectedItem]="1"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 2"
              (keyup)="controlForm()"
              placeholder="ABA"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="aba"
              type="text"
              [errorLogin]="errorLogin"
              labelName="ABA"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 3"
              (keyup)="controlForm()"
              placeholder="{{ 'MOVEMENTS.MONEY-OUT.INSTITUTION_NUMBER' | translate }}"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="institutionNumber"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.INSTITUTION_NUMBER' | translate }}"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 3"
              (keyup)="controlForm()"
              placeholder="{{ 'MOVEMENTS.MONEY-OUT.BRANCH_CODE' | translate }}"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="branchCode"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.BRANCH_CODE' | translate }}"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 3"
              (keyup)="controlForm()"
              placeholder="{{ 'MOVEMENTS.MONEY-OUT.BANK_NAME' | translate }}"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="bankName"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.BANK_NAME' | translate }}"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 4"
              (keyup)="controlForm()"
              placeholder="Sort Code"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="sortCode"
              type="text"
              [errorLogin]="errorLogin"
              labelName="Sort Code"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 5"
              (keyup)="controlForm()"
              placeholder="Código Bic/Swift"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="swiftCode"
              type="text"
              [errorLogin]="errorLogin"
              labelName="Código Bic/Swift"
              ></fun-input>
              <fun-input
              *ngIf="bankForm.value.accountType === 5"
              (selectChange)="controlForm()"
              placeholder="Selecciona el País"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="country"
              type="select"
              [errorLogin]="errorLogin"
              labelName="País"
              [items]="countryList"
              [selectedItem]="'AD'"
              ></fun-input>
            </div>
            <div class="flex md:block items-center relative">
              <fun-new-button [buttonType]="sendXHR" [size]="'xl'" (eventClick)="save()"
            title="{{ 'MOVEMENTS.MONEY-OUT.CREATE-ACCOUNT' | translate }}" type="submit" class="w-full button"></fun-new-button>
            </div>
          </div>
        </form>
    </div>
    <div *ngIf="successOperation" class="sucess">
        <img src="../../assets/images/illustrations/plants.svg" alt="congratulations">
        <div class="w-full">
            <h2>{{ 'MOVEMENTS.MONEY-OUT.INCOMING-DATA' | translate }}</h2>
            <p>{{ 'MOVEMENTS.MONEY-OUT.CHECKING-INFO' | translate }}</p>
        </div>
        <div class="w-full">
            <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.VIEW-PROJECTS' | translate }}"
            (eventClick)="goProjects()" class="button" [classExtra]="' !w-full '"></fun-new-button>
        </div>
    </div>
  </div>
</div>
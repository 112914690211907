<div class="general-container">
  <div class="body-content">
    <div class="documents-section pt-12 md:pt-32 md:pl-[16px]">
      <div class="document-list border border-eggplant-100/[0.1] rounded-2xl md:mb-16">
        <div *ngFor="let document of documents" class="document">
          <p [ngClass]="{'selected': document.selected && documents.length > 1 }" class="block font-sans text-eggplant-80 text-l"
            (click)="selectDocument(document.id)">{{ document.name }} (PDF)</p>
        </div>
      </div>
      <div class="document-info">
        <p class="font-sans text-center text-gray-40 text-l">{{ 'INVEST.READ_DOCUMENTS' | translate }}</p>
      </div>
      <div class="pt-24 hidden md:flex justify-center">
        <p *ngIf="currentPage !== undefined && numberOfPages !== undefined"
        class="font-sans text-s border border-eggplant-100/[0.1] text-eggplant-100/[0.4] p-8 rounded-2xl text-center appear">Página {{ currentPage }} de {{ numberOfPages }}</p>
      </div>
      <div *ngIf="platform === 'web'" class="py-12">
        <fun-new-button size="m" (eventClick)="downloadDocumentation()"  [classExtra]="' !w-full '"
        [buttonType]="'ghost'" title="Descargar documentos" icon="download" [iconPosition]="'left'"></fun-new-button>
      </div>
    </div>
    <div [ngClass]="{'view-content': btnList, 'view-full-content': !btnList }">
      <div class="view-container">
        <fun-viewer [file]="url" [zoom]="zoom" [maxHeight]="true"
        (pageChangeEvent)="pageChange($event)" (pageNumberEvent)="getNumberOfPages($event)"></fun-viewer>
      </div>
    </div>
  </div>
  <div class="footer-content" *ngIf="btnList">
    <div class="btn_container first">
      <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
      [buttonType]="'ghost'" title="{{ 'local_invest.go_back' | translate }}"></fun-new-button>
    </div>
    <div class="btn_container secod">

      <fun-new-button size="xl" (eventClick)="next()"  [classExtra]="' !w-full '"
      [buttonType]="'colorFull'" title="firmar"></fun-new-button>
    </div>
  </div>
</div>

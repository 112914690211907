<div class="general-container flex flex-col justify-center items-center content-center">
    <div *ngIf="showVideo" class="welcome">
        <div class="flex flex-col justify-center items-center content-center w-full pt-64 pb-20">
            <h2 class="font-sans text-xl md:text-2xl text-eggplant-100 text-center">{{ 'Wallet_activated.title' | translate }}</h2>
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4] text-center">{{ 'Wallet_activated.Video_txt' | translate }}</p>
        </div>
        <lite-youtube *ngIf="!loading" class="relative w-[800px] h-[480px] rounded-2xl" videoplay="Ver video" videotitle="Onboarding Fundeen" videoid="0_oLMHMEcaI" params="controls=0&autoplay=1&loop=1&playlist=0_oLMHMEcaI&rel=0&showinfo=0&modestbranding=1" short autoload></lite-youtube>
        <div *ngIf="loading" class="relative w-[800px] h-[480px] rounded-2xl">
            <fun-spinner></fun-spinner>
        </div>
        <div *ngIf="!loading" class="flex flex-col justify-center items-center content-center pt-20 pb-32">
            <h4 class="flex flex-row gap-x-12 font-sans text-xl text-eggplant-100/[0.4]">
              {{ 'Wallet_activated.Video_question' | translate }}
              <span class="flex justify-center items-center content-center min-w-[64px] text-l text-white uppercase linear-gradient-2 py-2 px-8 rounded-md font-sans hover:cursor-pointer" (click)="onClickValoration('si')">{{ 'Wallet_activated.Video_well' | translate }}</span>
              <span class="flex justify-center items-center content-center min-w-[64px] text-l text-eggplant-100 uppercase border border-eggplant-10 py-2 px-8 rounded-md font-sans hover:cursor-pointer" (click)="onClickValoration('no')">{{ 'Wallet_activated.Video_bad' | translate }}</span>
              <span class="flex justify-center items-center content-center min-w-[64px] text-m text-eggplant-70 uppercase py-2 px-8 rounded-md font-sans hover:cursor-pointer" (click)="onClickValoration('saltar')">{{ 'Wallet_activated.Video_continue' | translate }}</span>
            </h4>
        </div>
    </div>
    <div *ngIf="!showVideo" class="flex justify-center items-center content-center w-8/12 gap-96 pt-64 pb-20">
        <div class="text-container flex flex-col gap-20">
            <h2 class="font-sans text-xl md:text-2xl text-eggplant-100 pb-12">{{ 'Wallet_activated.title' | translate }} {{ 'Wallet_activated.start' | translate }}</h2>
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4]" *ngIf="!loading && (info.status > 4 && info.status !== 12)">{{ 'Wallet_activated.Txt_1_financed' | translate }}</p>
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4]" *ngIf="!loading && (info.status <= 4 || info.status === 12)">{{ 'Wallet_activated.Txt_1_future' | translate }}</p>
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4]" *ngIf="!loading">{{ 'Wallet_activated.you_can' | translate }} <span (click)="transfer()">{{ 'Wallet_activated.transfer' | translate }}</span> {{ 'Wallet_activated.Txt_2' | translate }}</p>

            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>
            <div class="mob-txt-skl content-skl" *ngIf="loading">
                <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            </div>

            <fun-skeleton-list *ngIf="loading"></fun-skeleton-list>
        </div>
        <div class="card-container mb-10">
            <fun-skeleton-card *ngIf="loading"></fun-skeleton-card>
            <fun-new-card *ngIf="!loading" [project]="info" [size]="'m'" (viewProjectEvent)="emitClickProject($event, info)"></fun-new-card>
        </div>
    </div>
</div>
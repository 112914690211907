<div [ngClass]="{'full': opened }">
  <div *ngIf="!opened">
    <div (mouseenter)="toggleHoverCard('enter')" (mouseleave)="toggleHoverCard('leave')" *ngIf="loaded" class="max-w-[780px] z-10" [ngClass]="{'animate-card': opened }">
      <div class={{itemClass}} (click)="viewProject()" >
        <div class={{contentClass}}>
          <header class="flex flex-col gap-16">
              <p class={{titleClass}}>
                <span *ngIf="cardType !== 'portfolio'">{{ project.name }}</span>
                <span *ngIf="cardType === 'portfolio'">{{ project.projectName }}</span>
              </p>
              <p *ngIf="cardType !== 'marketplace'" class={{descriptionClass}}>{{ project.description }}</p>
              <div *ngIf="cardType === 'marketplace'">
                <p *ngIf="offers.length === 0" class={{descriptionClass}}>{{ 'project-detail.No_project_text' | translate }}</p>
                <p *ngIf="offers.length > 0" class={{descriptionClass}}>{{ 'project-detail.project_text' | translate }}</p>
              </div>
          </header>
          <ul class={{listClass}} *ngIf="!isFlexibleCompensation">
              <li *ngIf="cardType === 'generic'" class="relative before:content-['objetivo'] font-sans">{{ project.total | localeNumber }} €</li>
              <li *ngIf="cardType === 'generic'" class="relative min-w-[65px] before:content-['rentabilidad'] font-sans">{{ project.profitability | localeNumber }}%</li>
              <li *ngIf="cardType === 'marketplace'" class="relative min-w-[65px] before:content-['rentabilidad'] font-sans">{{ project.global_profitability | localeNumber }}%</li>
              <li *ngIf="cardType === 'generic'" class="relative before:content-['plazo'] font-sans">{{ project.id !== 130 ? project.term : '6,5' }} años</li>
              <li *ngIf="cardType === 'marketplace'" class="relative min-w-[65px] before:content-['tiempo'] font-sans">
                <ng-container *ngIf="years > 0">
                  {{ years }}
                  <span *ngIf="years > 1" class="no-resalt">{{ 'card.years' | translate }}</span>
                  <span *ngIf="years == 1" class="no-resalt">{{ 'card.year' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="months > 0">
                    {{ months }}
                    <span *ngIf="months > 1" class="no-resalt">{{ 'card.months' | translate }}</span>
                    <span *ngIf="months == 1" class="no-resalt">{{ 'card.month' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="years <= 0 && months <= 0"> - </ng-container>
              </li>

              <li *ngIf="cardType === 'portfolio' && project.participationsBalance !== null && project.participationsBalance !== undefined && project.participationsBalance > 0" class="relative min-w-[65px] before:content-['Invertido'] font-sans">{{ project.invested | localeNumber }} €</li>
              <li *ngIf="cardType === 'portfolio' && (project.participationsBalance === null || project.participationsBalance === undefined || project.participationsBalance === 0)" class="relative min-w-[65px] before:content-['Capital'] font-sans">{{ project.invested | localeNumber }} €</li>

              <li *ngIf="cardType === 'portfolio' && project.status !== 9" class="relative min-w-[65px] before:content-['Rentabilidad'] font-sans">
                <span *ngIf="project.status !== 13">{{ (project.globalProfitability ? project.globalProfitability : project.profitability) | localePercentNumber }}%</span>
                <span *ngIf="project.status === 13">0%</span>
              </li>

              <li *ngIf="cardType === 'portfolio' && project.status === 9 && project.projectId === 17" class="relative min-w-[65px] before:content-['Rentabilidad'] font-sans">
                <span>{{ project.userProfitability | localePercentNumber }}%</span>
              </li>

              <li *ngIf="cardType === 'portfolio'" class="relative min-w-[65px] before:content-['Beneficios'] font-sans">{{ project.earned | localeNumber }} €</li>
          </ul>
          <fun-new-button #btnCard [title]="cardType !== 'marketplace' ? btnTitle : mkpBtntitle" [icon]="'arrow-right'" [size]=[btnSize] [buttonType]=[btnType] [iconPosition]="'right'"></fun-new-button>
        </div>
        <footer class={{imageClass}} [ngStyle]="{'backgroundImage':'url(' + project.imageCard + ')', 'background-size': 'cover', 'background-position': 'center'}"></footer>
      </div>
    </div>
  </div>
</div>

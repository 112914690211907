import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MovementsService } from 'src/app/services/movements.service';
import { ActionSheetService } from '../../../action-sheet/action-sheet.service';
import { IncomeInfoComponent } from '../../../income-info/income-info.component';
import { ToastService } from '../../../toast/toast.service';
import { CreateBankAccountService } from './money-out-change-account.service';
import { BICValidator, IbanValidator, conditionalValidator } from './money-out-change-account.validator';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-money-out-change-account',
  templateUrl: './money-out-change-account.component.html',
  styleUrls: ['./money-out-change-account.component.scss'],
})
export class MoneyOutChangeAccountComponent implements OnInit {
  @ViewChild('documentId', {static: false}) documentId: ElementRef;
  @Output() public goProject = new EventEmitter<any>();
  @Input() public walletId: any;
  @Input() public username: any;

  public successOperation = false;
  private fb: FormBuilder;
  public bankForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorLogin = false;
  public moneyOutInfo;
  public skeletonActive = false;
  public sendXHR;

  errorIban = false;
  errorFile = false;
  errorFileId;
  errorFileText;

  mandatoryFields;
  countryList = [];
  accountTypeList = [
    { id: 1, name: 'IBAN' },
    { id: 2, name: 'US' },
    { id: 3, name: 'CA' },
    { id: 4, name: 'GB' },
    { id: 5, name: 'Otro' },
  ];

  constructor(
    private chdRef: ChangeDetectorRef,
    private bankService: CreateBankAccountService,
    private toastService: ToastService,
    private translate: TranslateService,
    public actionSheetService: ActionSheetService,
    private movementsService: MovementsService,
    private utils: UtilsService
    ) {
    this.bankForm = new FormGroup({
      bank: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      iban: new FormControl('', [Validators.required, Validators.pattern('^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$')]), // IbanValidator
      accountType: new FormControl('', [Validators.required]),
      aba: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 2,
          Validators.required,
          Validators.pattern('^\\d{9}$')
        )
      ]),
      institutionNumber: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 3,
          Validators.required,
          Validators.pattern('^\\d{3}$')
        )
      ]),
      branchCode: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 3,
          Validators.required,
          Validators.pattern('^\\d{5}$')
        )
      ]),
      bankName: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 3,
          Validators.required,
          Validators.pattern('^[\\w\\s]{1,50}')
        )
      ]),
      sortCode: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 4,
          Validators.required,
          Validators.pattern('\\d{6}')
        )
      ]),
      swiftCode: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 5,
          Validators.required
        )
      ]),
      country: new FormControl('', [
        conditionalValidator(
          () => this.bankForm && this.bankForm.get('accountType')?.value === 5,
          Validators.required
        )
      ]),
    });

    this.formControlKeys = Object.keys(this.bankForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
      minlength: this.translate.instant('MOVEMENTS.MONEY-OUT.CHARACTERS'),
      maxlength: this.translate.instant('MOVEMENTS.MONEY-OUT.CHARACTERS')
    };
  }

  ngOnInit() {
    this.sendXHR = 'primaryDark-disabled';
    this.countryList = this.utils.getCountryList();
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
  }

  openInfo() {
    const modalParams = {
      page: 'cash-out'
     };

    this.actionSheetService.showModal(IncomeInfoComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('MOVEMENTS.DEPOSIT-FUNDS.INCOME-INFORMATION').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.onClose().subscribe();
      }
    );
  }

  controlForm() {
    if (this.bankForm.valid) {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  save() {
    this.isSubmit = true;
    this.chdRef.detectChanges();
    if (this.bankForm.valid) {
      this.mandatoryFields = false;
    } else {
      this.mandatoryFields = true;
    }
    if (!this.mandatoryFields) {
      this.sendXHR = 'primaryDark';
      this.bankForm.value.iban = this.cleanString(this.bankForm.value.iban);
      let params = {
        holder: this.bankForm.value.bank,
        iban: this.bankForm.value.iban,
        wallet: this.walletId,
        accountType: this.bankForm.value.accountType
      };

      switch (this.bankForm.value.accountType) {
        case 2:
          params['aba'] = this.bankForm.value.aba;
          break;
        case 3:
          params['institutionNumber'] = this.bankForm.value.institutionNumber;
          params['branchCode'] = this.bankForm.value.branchCode;
          params['bankName'] = this.bankForm.value.bankName;
          break;
        case 4:
          params['sortCode'] = this.bankForm.value.sortCode;
          break;
        case 5:
          params['swiftCode'] = this.bankForm.value.swiftCode;
          params['country'] = this.bankForm.value.country;
          break;
      }

      this.createAccount(params);
      this.sendXHR = 'primaryDark-disabled';
      this.chdRef.detectChanges();
    }
  }
  cleanString(value) {
    let item;
    item = value.toUpperCase();
    item = value.trim();
    item = value.replace(/\s/g, '');
    return item;
  }

  goProjects() {
    this.goProject.emit();
  }

  createAccount(model) {
    this.movementsService.createAccount(model).subscribe(() => {
      this.successOperation = true;
      this.chdRef.detectChanges();
    }, error => {
      this.successOperation = false;
      this.isSubmit = false;
      this.toastService.showToast(this.translate.instant('MOVEMENTS.MONEY-OUT.ERROR'), 'warning');
      this.chdRef.detectChanges();
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }
}

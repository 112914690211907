<div *ngIf="page === 'deposit'" class="advice flex gap-12">
    <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
    <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'Deposit-funds.In-addition' | translate }}</p>
</div>
<div *ngIf="page === 'cash-out'" class="cash-out flex gap-12">
    <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
    <div class="flex flex-col gap-8">
        <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'Deposit-funds.withdraw-money' | translate }}</p>
        <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'Deposit-funds.change-text' | translate }}</p>
    </div>
</div>
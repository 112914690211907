<div *ngIf="step === 0">
  <div class="general-container flex flex-col md:flex-row justify-center gap-32">
    <div class="fixed md:hidden hover:cursor-pointer top-[46px] right-[22px]">
        <fun-icon [name]="'info-circle'" [style]="'w-32 h-32 stroke-gray-40 stroke-2'" (click)="openInfo()"></fun-icon>
    </div>  
    <div class="header flex flex-col divide-y divide-gray-10 gap-12 w-full md:w-1/2 p-20 rounded-xl bg-gray-10/[0.1]" *ngIf="bankNumber !== null">
        <div class="titleContainer pb-16">
            <h2 class="font-sans text-eggplant-100 text-xl pb-16">{{ 'MOVEMENTS.MONEY-OUT.HOW' | translate }}</h2>
            <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.MONEY-OUT.WAY-WITHDRAW' | translate }}</p>
        </div>
        <div class="desktop-adv flex gap-12 pt-20">
          <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
          <div class="flex flex-col gap-8">
            <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.MONEY-OUT.TEXT-1' | translate }}</p>
            <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.MONEY-OUT.TEXT-2' | translate }}</p>
          </div>
        </div>
    </div>
    <div class="content operation w-full md:w-1/2 p-0 md:p-20" *ngIf="bankNumber !== null">
        <h2 class="font-sans text-eggplant-100 text-xl pb-16">{{ 'MOVEMENTS.MONEY-OUT.BANK-TRANSFER' | translate }} </h2>
        <form [formGroup]="cashForm" (submit)="outMoney()" >
            <div class="flex flex-col gap-32">
                <div class="flex flex-col gap-12">
                    <h4 class="font-sans text-eggplant-80 text-l">{{ 'MOVEMENTS.MONEY-OUT.TRANSFER-TO' | translate }}</h4>
                    <div class="bank-count">
                        <div class="bank">
                            <h3 class="font-sans text-eggplant-80 text-l" *ngIf="loading !== true">{{ bankNumber }} <span class="change text-m" (click)="change()">{{ 'MOVEMENTS.MONEY-OUT.CHANGE' | translate }}</span></h3>
                            <ion-skeleton-text *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
                        </div>
                    </div>
                    <label *ngIf="loading !== true" class="available font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.MONEY-OUT.AVAILABLE' | translate }} {{ available | localeNumber }} €</label>
                    <ion-skeleton-text class="available" *ngIf="loading" style="width: 30%" animated></ion-skeleton-text>
                    <fun-input
                    *ngIf="loading !== true"
                    [disabled]="disableInputs"
                    placeholder="00.00 €"
                    [parentForm]="cashForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="money"
                    type="number"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'MOVEMENTS.MONEY-OUT.QUANTITY-WITHDRAW' | translate }}"
                    ></fun-input>
                    <fun-input
                    *ngIf="loading !== true"
                    [disabled]="disableInputs"
                    placeholder="ej: {{ 'MOVEMENTS.MONEY-OUT.DIVIDENDS' | translate }}"
                    [parentForm]="cashForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="concept"
                    type="text"
                    [errorLogin]="errorConcept"
                    labelName="{{ 'MOVEMENTS.MONEY-OUT.CONCEPT' | translate }}"
                        ></fun-input>
                        <div *ngIf="loading" class="inp-container">
                            <div class="inp">
                            <ion-skeleton-text style="width: 90%; height: 12px;" animated></ion-skeleton-text>
                            </div>
                            <div class="inp">
                            <ion-skeleton-text style="width: 90%; height: 12px;" animated></ion-skeleton-text>
                            </div>
                        </div>
                </div>
                <div class="flex md:block items-center relative">
                    <fun-new-button [size]="'xl'" [buttonType]="sendXHR" title="{{ 'MOVEMENTS.MONEY-OUT.TRANSFER' | translate }}" type="submit" class="button"
                    (eventClick)="outMoney()"></fun-new-button>
                </div>
            </div>
        </form>
    </div>
    <div class="success flex flex-col items-center justify-center px-20 py-0 md:p-20 w-full md:w-2/3 gap-64" *ngIf="bankNumber === null">
        <fun-icon [name]="'check-circle'" [style]="'w-96 h-96 stroke-2 stroke-pink-120'"></fun-icon>
        <div class="flex flex-col items-center justify-center gap-32">
            <h2 class="font-sans text-eggplant-100 text-xl">{{ 'MOVEMENTS.MONEY-OUT.SUCCESSFUL' | translate }}</h2>
            <p class="text-center">{{ 'MOVEMENTS.MONEY-OUT.TEXT-3' | translate }}</p>
        </div>
        <div class="flex flex-col md:flex-row gap-x-24 gap-y-12">
            <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.PROJECTS' | translate }}"
                (eventClick)="goProjects()" class="button" [classExtra]="' !w-full '"></fun-new-button>
            <fun-new-button [buttonType]="'secondary'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.MOVEMENTS' | translate }}"
                (eventClick)="closeModal()" class="secondary" [classExtra]="' !w-full '"></fun-new-button>
        </div>
    </div>
  </div>
</div>
<div *ngIf="step === 1">
    <app-money-out-change-account [walletId]="walletId" [username]="username" (goProject)="goProjects()"></app-money-out-change-account>
</div>
<div *ngIf="step === 2">
    <fun-overal-message [text]="text" [image]="2" ></fun-overal-message>
</div>
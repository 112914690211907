<div class="general-container" [ngClass]="{'flexed': step !== 0, 'block': step === 0}">
  <div id="checkout-page" class="span-container">
      <!--<span id="item-name">{{ headerData.name }}</span>
      <span id="item-id">{{ data.investInfo.id }}</span>
      <span id="item-brand">{{ data.spanInfo.company }}</span>
      <span id="item-category">Energía Solar</span>
      <span id="item-location">{{ data.spanInfo.location }}, ES</span>
      <span id="item-profit">{{ headerData.profitability | number:'1.0-2':'es' }}</span>
      <span id="item-goal">{{ headerData.objective | number:'1.0-2':'es' }} €</span>
      <span id="item-index">{{ data.spanInfo.position }}</span>-->
      <span id="list-name">En financiación</span>
  </div>

  <div *ngIf="isDocumentSigned" id="sign-documents" class="span-container">
      <span id="payment-type">{{ paymentType }}</span>
      <!--<span id="item-name">{{ data.headerInfo.name }}</span>
      <span id="item-id">{{ data.investInfo.id }}</span>
      <span id="item-brand">{{ data.spanInfo.company }}</span>
      <span id="item-category">Energía Solar</span>
      <span id="item-location">{{ data.spanInfo.location }}, ES</span>
      <span id="item-profit">{{ data.headerInfo.profitability | number:'1.0-2':'es' }}</span>
      <span id="item-goal">{{ data.headerInfo.objective | number:'1.0-2':'es' }} €</span>
      <span id="item-index">{{ data.spanInfo.position }}</span>-->
      <span id="item-price">{{ data.moneyInvested }}</span>
      <span id="list-name">En financiación</span>
  </div>

  <div class="header-container">
      <div *ngIf="step > 0 && step < 4 && !isTimeStop" class="mobile-header" (click)="showInfo()">
          <div [ngClass]="{'project': step === 2 || step === 3}">
              <h3 class="font-sans text-eggplant-100 text-xl">{{ headerData.name }}</h3>
              <p class="font-sans text-gray-25 text-h400">{{ 'invest.Header.Miss' | translate }} {{ headerData.objective - headerData.contributed | localeNumber }} €</p>
              <img *ngIf="step === 1" src="../../../../assets/images/invest/down.svg" alt="arrow down">
          </div>
      </div>
      <div *ngIf="step > 0 && step < 4 && !isTimeStop" class="desktop-header flex place-content-around">
          <div class="project-name cell">
              <p class="font-sans text-eggplant-100 text-xl">{{ headerData.name }}</p>
          </div>

          <div *ngIf="step === 1" class="flex border border-eggplant-100/[0.1] rounded-2xl pb-[8px] px-[16px]">
              <div class="pr-24 border-r border-r-eggplant-100/[0.1] mt-[8px]">
                  <h4 class="font-sans text-m text-eggplant-100/[0.25]">{{ 'invest.Header.Estimated' | translate }}</h4>
                  <p class="font-sans text-eggplant-100 text-xl">{{ headerData.profitability | localePercentNumber }}% {{ 'invest.Header.Annual' | translate }}</p>
              </div>
              <div class="px-24 border-r border-r-eggplant-100/[0.1] mt-[8px]">
                  <h4 class="font-sans text-m text-eggplant-100/[0.25]">{{ 'invest.Header.deadline' | translate }}</h4>
                  <p class="font-sans text-eggplant-100 text-xl">{{ headerData.term | number:'1.0-0':'es' }} {{ 'invest.Header.years' | translate }}</p>
              </div>
              <!---->
              <div class="pl-24">
                <div class="graphic-container cell">
                    <p class="font-sans text-gray-25 text-h400 text-right" *ngIf="headerData.status !== 15">{{ 'invest.Header.Miss' | translate }}
                        <span>{{ headerData.objective - headerData.contributed | localeNumber }} €</span>
                    </p>
                    <p class="font-sans text-gray-25 text-h400 text-right" *ngIf="headerData.status === 15">{{ 'invest.Header.finish_in' | translate }}
                        <span>{{ daysLeft | number:'1.0-0':'es' }} {{ 'invest.Header.days' | translate }}</span>
                    </p>
                    <div class="bar-grap-container">
                        <div class="bar-container">
                            <div [ngStyle]="{ width: percentReserve + '%' }" class="barReserve"></div>
                            <div [ngStyle]="{ width: percentInvested + '%' }" class="barProgress linear-gradient-2" [ngClass]="{ 'fullRounded' : percentReserve > 50}"></div>
                        </div>
                    </div>
                    <p class="font-sans text-eggplant-100/[0.25] text-m text-right">{{ 'invest.Popup.objective' | translate }} {{ headerData.objective | localeNumber }} €</p>
                </div>
              </div>
          </div>
          <div  *ngIf="step === 1">
            <img class="w-[172px] h-[90px] rounded-2xl" [src]="headerData.projectImg" alt="imagen del proyecto">
          </div>
          <div *ngIf="step === 2 || step === 3" class="cell flex">
                <p class="font-sans text-white text-m num-step bg-eggplant-100/[0.25]">1</p>
              <p class="font-sans text-eggplant-100/[0.25] text-m">{{ 'INVEST.INVESTMENT' | translate }}: {{ invest | localeNumber }} €</p>
          </div>
          <div *ngIf="step === 2 || step === 3" class="cell flex">
            <p class="font-sans text-white text-m num-step"
            [ngClass]="{'bg-eggplant-100/[0.25]': step === 3, 'linear-gradient-2': step === 2 }">2</p>
            <p class="font-sans text-m" [ngClass]="{'text-eggplant-100/[0.25]': step === 3, 'bg-gradient-3-text': step === 2 }">Documentación y firma</p>
          </div>
          <div *ngIf="(step === 2 || step === 3) && investByCard" class="cell flex">
            <p class="font-sans text-white text-m num-step"
            [ngClass]="{'bg-eggplant-100/[0.25]': step === 2, 'linear-gradient-2': step === 3 }">3</p>
            <p class="font-sans text-m" [ngClass]="{'text-eggplant-100/[0.25]': step === 2, 'bg-gradient-3-text': step === 3 }">{{ 'INVEST.PAY' | translate }}</p>
          </div>
          <div *ngIf="step === 2 || step === 3" class="cell flex">
            <p class="font-sans text-white text-m num-step bg-eggplant-100/[0.25]">
                <span *ngIf="!investByCard">3</span>
                <span *ngIf="investByCard">4</span>
            </p>
            <p class="font-sans text-eggplant-100/[0.25] text-m">{{ 'AUTOINVEST.HEADER.TITLE_5' | translate }}</p>
          </div>
      </div>
  </div>
  <app-invest-test-convenience (emitSuccess)="testSucess($event)" (testFail)="testFail()" [testInfo]="testInfo" [profile]="profile" *ngIf="step === 0"></app-invest-test-convenience>
  <app-invest-reservation *ngIf="step === 1 && !loaderInvestProcess && headerData.status === 15" [amount]="amount" [data]="bodyData" [modelData]="data.investInfo" (increaseToInvest)="getWantToIncrease($event)"
  (eventCloseModalCard)=" newInvest()" (deleteHeaderEvent)="deleteHeader($event)" (reserveHubspotEvent)="updateHubsportReservation($event)"></app-invest-reservation>
  <app-invest-body *ngIf="step === 1 && !loaderInvestProcess && headerData.status !== 15" (moneyToInvest)="getMoneyToInvest($event)" (amountValue)="getAmountValue($event)" (openTestEvent)="showTest()"
  (increaseToInvest)="getWantToIncrease($event)" (setPayType)="getPayType($event)" (setCodePromotion)="getCodePromotion($event)" (localInvestIdEvent)="getLocalInvestId($event)"
  (addPromotionalMoneyEvent)="getPromotionalMoney($event)" [data]="bodyData" [errors]="errors" [amount]="amount" [localInvestProject]="localInvestProject" [profile]="profile"></app-invest-body>
  <div class="skl-list" *ngIf="step === 1 && loaderInvestProcess">
      <fun-spinner *ngIf="step === 1 && loaderInvestProcess"></fun-spinner>
  </div>
  <div class="ifr-container"  *ngIf="(step === 2 && !previewDocuments) || step === 3">
    <app-invest-ifr
    style="height: 100%;"
        *ngIf="step === 2"
        [url]="transaction.signaturit"
        [ifr]="1"
        [projectData]="data"
        (signEvent)="isSigned($event);"
        (signErrorEvent)="signError()"
        (startSignEvent)="startSign($event);"
        (signaturitIssueEvent)="signaturitIssue()"
        (editInvestEvent)="editInvest()"
        (goToPreviewDocumentsEvent)="goToSignDocuments()"
    ></app-invest-ifr>
    <app-invest-mangopay
        *ngIf="step === 3 && investByCard"
        [investData]="transaction"
        [mpWalletModel]="data.investInfo.wallet"
        (editInvestEvent)="editInvest()"
        (eventStopTime)="stopTime()"
        (successInvested)="successInvestedCard()"
    ></app-invest-mangopay>
  </div>
    <div class="preview-container" *ngIf="step === 2 && previewDocuments">
        <app-invest-document-viewfinder (prevEvent)="editInvest()" (goToSignDocuments)="goToSignDocuments()" [documents]="data.signDocuments"></app-invest-document-viewfinder>
    </div>
  <app-invest-success
      *ngIf="step === 4"
      (eventShare)="share()"
      (eventGoHome)="goHome()"
      (closeModalEvent)="newInvest()"
      [refCode]="data.investInfo.refCode"
      [amount]="invest"
      [name]="headerData.name"
      [prevInvest]="headerData.invested"
      [data]="data"
  ></app-invest-success>
  <!-- <fun-invest-success-taxdown *ngIf="step === 4 && hubspotQuestion" [username]="profile.username" (closeModalEvent)="goHome()"></fun-invest-success-taxdown> -->
</div>

<div *ngIf="!loading" class="general-container flex flex-col md:flex-row justify-center gap-32">
  <div class="header flex flex-col divide-y divide-gray-10 gap-12 w-full md:w-1/2 p-20 rounded-xl bg-gray-10/[0.1]">
      <div class="titleContainer pb-16">
          <h2 class="font-sans text-eggplant-100 text-xl pb-16">{{ 'MOVEMENTS.DEPOSIT-FUNDS.HOW-DEPOSIT' | translate }}</h2>
          <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.WAY-DEPOSIT' | translate }}</p>
          <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.WE-EXPLAIN' | translate }}</p>
      </div>
      <div class="advice flex gap-12 pt-20">
        <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
        <p class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.IN-ADDITION' | translate }}</p>
      </div>
  </div>
  <div class="content p-0 md:p-20">
      <h2 class="font-sans text-eggplant-100 text-xl pb-16">{{ 'MOVEMENTS.DEPOSIT-FUNDS.BANK-TRANSFER' | translate }}</h2>
      <div class="flex flex-col">

        <div class="flex flex-col w-full pt-0 pb-12 justify-between relative">
          <div class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[14px] z-[1]"></div>
          <div>
            <header class="flex flex-row items-start gap-12">
              <div class="flex items-center">
                <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white bg-orange-100 z-[10]">
                  <span class="flex justify-center items-center content-center font-sans text-white">1</span>
                </span>
              </div>
              <div class="flex flex-col pt-2">
                <h4 class="flex flex-row gap-x-12 font-sans text-l text-eggplant-100">
                    {{ 'MOVEMENTS.DEPOSIT-FUNDS.ENTER-BANK' | translate }}
                </h4>
                <p class="font-sans text-m text-eggplant-100/[0.4]">{{ 'MOVEMENTS.DEPOSIT-FUNDS.BANK-ACCOUNT' | translate }}</p>
              </div>
            </header>
          </div>
        </div>

        <div class="flex flex-col w-full pt-0 pb-12 justify-between relative">
          <div class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[14px] z-[1]"></div>
          <div>
            <header class="flex flex-row items-start gap-12">
              <div class="flex items-center">
                <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white bg-orange-100 z-[10]">
                  <span class="flex justify-center items-center content-center font-sans text-white">2</span>
                </span>
              </div>
              <div class="flex flex-col pt-2">
                <h4 class="flex flex-row gap-x-12 font-sans text-l text-eggplant-100">
                    {{ 'MOVEMENTS.DEPOSIT-FUNDS.CONFIGURE-TRANSFER' | translate }}
                </h4>
                <div class="flex flex-col divide-y divide-gray-10 py-8">
                  <div class="detailContainer relative py-4 px-8">
                      <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.RECIVER' | translate }}</h4>
                      <p class="font-sans text-eggplant-100 text-l">{{ addressee }}</p>
                      <div class="copy" title="Copiar" >
                        <fun-icon (click)="copy(addressee)" [name]="'copy'" [style]="'w-20 h-20 stroke-2 stroke-eggplant-70'"></fun-icon>
                      </div>
                  </div>
                  <div class="detailContainer relative py-4 px-8">
                      <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.CODE' | translate }}</h4>
                      <p class="font-sans text-eggplant-100 text-l">{{ profile.mangopayBankingAliasBic }}</p>
                      <div class="copy" title="Copiar" >
                        <fun-icon (click)="copy(profile.mangopayBankingAliasBic)" [name]="'copy'" [style]="'w-20 h-20 stroke-2 stroke-eggplant-70'"></fun-icon>
                      </div>
                  </div>
                  <div class="detailContainer relative py-4 px-8">
                      <h4 class="font-sans text-eggplant-100/[0.4] text-m">IBAN</h4>
                      <p class="font-sans text-eggplant-100 text-l">{{ profile.mangopayBankingAliasIban }}</p>
                      <div class="copy" title="Copiar" >
                        <fun-icon (click)="copy(profile.mangopayBankingAliasIban)" [name]="'copy'" [style]="'w-20 h-20 stroke-2 stroke-eggplant-70'"></fun-icon>
                      </div>                      
                  </div>
                  <div class="detailContainer relative py-4 px-8">
                      <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.ORDERED' | translate }}</h4>
                      <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.SENDER-TRANSFER' | translate }}</p>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>

        <div class="flex flex-col w-full pt-0 pb-12 justify-between relative">
            <div class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[14px] z-[1]"></div>
            <div>
              <header class="flex flex-row items-start gap-12">
                <div class="flex items-center">
                  <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white bg-orange-100 z-[10]">
                    <span class="flex justify-center items-center content-center font-sans text-white">3</span>
                  </span>
                </div>
                <div class="flex flex-col pt-2">
                  <h4 class="flex flex-row gap-x-12 font-sans text-l text-eggplant-100">
                    {{ 'MOVEMENTS.DEPOSIT-FUNDS.MAKE-TRANSFER' | translate }}
                  </h4>
                </div>
              </header>
            </div>
        </div>

        <div class="flex flex-col w-full pt-0 pb-12 justify-between relative">
            <div class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[14px] z-[1]"></div>
            <div>
              <header class="flex flex-row items-start gap-12">
                <div class="flex items-center">
                  <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white bg-orange-100 z-[10]">
                    <span class="flex justify-center items-center content-center font-sans text-white">4</span>
                  </span>
                </div>
                <div class="flex flex-col pt-2">
                  <h4 class="flex flex-row gap-x-12 font-sans text-l text-eggplant-100">
                    {{ 'MOVEMENTS.DEPOSIT-FUNDS.WAIT' | translate }}
                  </h4>
                </div>
              </header>
            </div>
        </div>

        <div class="flex flex-col w-full pt-0 pb-12 justify-between relative">
          <div>
            <header class="flex flex-row items-start gap-12">
              <div class="flex items-center">
                <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white bg-orange-100 z-[10]">
                  <span class="flex justify-center items-center content-center font-sans text-white">5</span>
                </span>
              </div>
              <div class="flex flex-col pt-2">
                <h4 class="flex flex-row gap-x-12 font-sans text-l text-eggplant-100">
                    {{ 'MOVEMENTS.DEPOSIT-FUNDS.START-INVEST' | translate }}
                </h4>
              </div>
            </header>
          </div>
        </div>
      </div>
  </div>
</div>
<div *ngIf="loading">
    <fun-spinner></fun-spinner>
</div>

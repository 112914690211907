<div class="modal-container" #component>
    <div class="bg_shadow" (click)="closeM()"></div>
    <div class="modal" [ngClass]="{'appearUp': enableTransitionUp, 'dissapearDown': (closeProccess && enableTransitionDown)}">
        <div class="modal-header">
            <div class="close" *ngIf="!hideButton" (click)="close()">
                <fun-icon [name]="'x-close'" [style]="'w-32 h-32 stroke-gray-40 stroke-2'"></fun-icon>
            </div>
            <div class="modal-status ml-32">
                <!-- <h2>{{ title }}</h2> -->
                <label *ngIf="status" class="label-tag"
                    [ngClass]="{
                        'label-tag-orange': status == 'active' || status == 'pending_acceptation',
                        'label-tag-green': (status == 'won' && offer_status === 'sold') || status == 'sold',
                        'label-tag-red': (status == 'lost' || (status === 'won' && offer_status === 'not_sold')) || status == 'not_sold'}">
                    <span *ngIf="status == 'active' || status == 'pending_acceptation'">{{ 'bid.active-status' | translate }}</span>
                    <span *ngIf="status == 'won' && offer_status !== 'not_sold'">{{ 'bid.won-status' | translate }}</span>
                    <span *ngIf="status === 'won' && offer_status === 'not_sold'">{{ 'bid.expired' | translate }}</span>
                    <span *ngIf="status == 'lost'">{{ 'bid.lost-status' | translate }}</span>
                    <span *ngIf="status == 'sold'">{{ 'offer.sold-status' | translate }}</span>
                    <span *ngIf="status == 'not_sold'">{{ lostTagName }}</span>
                </label>
                <!-- <div class="status">
                    <div class="filter"></div>
                    <p>Status</p>
                </div> -->
            </div>
            <!-- <div class="info-container">
                <img alt="x" class="info" src="../../assets/images/img/ico-info.svg">
            </div> -->
            <div class="line"></div>
        </div>
        <div class="content-modal scrollbar-none flex items-center justify-center" [ngClass]="{'register-content-modal': reduceHeightDefault, 'normal-content-modal': !reduceHeightDefault }" >
            <div #componentPlaceholder></div>
        </div>
    </div>
</div>
